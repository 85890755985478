import React, { useEffect, useRef } from 'react';

const GoogleMap = ({ onMapLoad }) => {
  const mapRef = useRef(null);

  const initMap = (position) => {
    const { latitude, longitude } = position.coords || { latitude: 37.5665, longitude: 126.9780 }; // 기본 위치: 서울

    if (mapRef.current) {
      const map = new window.google.maps.Map(mapRef.current, {
        center: { lat: latitude, lng: longitude },
        zoom: 12,
        mapId: process.env.REACT_APP_GOOGLE_MAPS_MAP_ID,
      });

      if (onMapLoad) {
        onMapLoad(map);
      }
    }
  };

  const handleLocationError = (browserHasGeolocation, infoWindow, pos) => {
    infoWindow.setPosition(pos);
    infoWindow.setContent(
      browserHasGeolocation
        ? 'Error: The Geolocation service failed.'
        : 'Error: Your browser doesn\'t support geolocation.'
    );
    infoWindow.open(mapRef.current);
  };

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&callback=initMap&libraries=places,marker,geometry`;
      script.async = true;
      script.defer = true;
      window.initMap = () => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(initMap, (error) => {
            console.error('Error getting user location:', error);
            handleLocationError(true, new window.google.maps.InfoWindow(), { lat: 0, lng: 0 });
          });
        } else {
          handleLocationError(false, new window.google.maps.InfoWindow(), { lat: 0, lng: 0 });
        }
      };
      document.head.appendChild(script);
    };

    if (!window.google) {
      loadGoogleMapsScript();
    } else {
      window.initMap();
    }
  }, []);

  return <div ref={mapRef} style={{ width: '100%', height: '70vh', position: 'relative', top: 0, left: 0 }}></div>; // 높이를 100vh로 설정하여 전체 화면을 차지하도록 변경
};

export default GoogleMap;