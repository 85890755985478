import React, { useEffect, useRef, useState } from 'react';
import GoogleMap from 'components/googlemap';
import { Box, Typography } from '@mui/material';

const SearchBeach = () => {
  const [markers, setMarkers] = useState([]);
  const mapRef = useRef(null);

  const onMapLoad = (map) => {
    mapRef.current = map;

    // 지도가 로드된 후에 radius를 계산하고 place를 가져옵니다.
    map.addListener('idle', () => {
      const bounds = map.getBounds();
      const center = map.getCenter();
      const radius = calculateRadius(bounds, center);
      clearMarkers(); // 기존 마커 제거
      fetchPlaces(map, radius, 'natural_feature', 'beach')
        .then(places => {
          const sliced_places = places
            .sort((a, b) => b.user_ratings_total - a.user_ratings_total)
            .slice(0, 2);
          displayWeatherInfo(map, sliced_places, bounds);
          // displayPlaces(map, places); // 새로운 마커 표시
        })
        .catch(error => {
          console.error('fetchVisibleCities error:', error);
        });
    });
  };

  const displayWeatherInfo = async (map, places, bounds) => {

    for (const place of places) {
      const data = await fetchWeatherData(place.name, place.geometry.location.lat(), place.geometry.location.lng());
      const position = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
      createCustomMarker(map, position, place, data, bounds);
    }
  };

  const fetchWeatherData = async (name, lat, lon) => {
    const apiKey = process.env.REACT_APP_OPENWEATHERMAP_API_KEY;
    // console.log(`api.openweathermap(${name}, ${lat}:${lon}):`);
    const response = await fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${apiKey}&units=metric`);
    const data = await response.json();
    // console.log(`api.openweathermap(${name}, ${lat}:${lon}):`, data);
    return data;
  };

  const createCustomMarker = (map, position, city, data, bounds) => {
    class CustomMarker extends window.google.maps.OverlayView {
      constructor(position, city, data) {
        super();
        this.position = position;
        this.city = city;
        this.data = data;
        this.div = null;
      }

      draw() {
        const overlayProjection = this.getProjection();
        const position = overlayProjection.fromLatLngToDivPixel(this.position);

        const div = this.div;
        if (div) {
          div.style.left = position.x + 'px';
          div.style.top = position.y + 'px';
        }
      }

      onRemove() {
        if (this.div) {
          this.div.parentNode.removeChild(this.div);
          this.div = null;
        }
      }

      getWeatherIcon(keyword) {
        const weatherIcon = {
          'Thunderstorm': 'https://img.icons8.com/clouds/100/000000/storm.png',
          'Drizzle': 'https://img.icons8.com/clouds/100/000000/light-rain.png',
          'Rain': 'https://img.icons8.com/clouds/100/000000/rain.png',
          'Snow': 'https://img.icons8.com/clouds/100/000000/snow.png',
          'Clear': 'https://img.icons8.com/clouds/100/000000/sun.png',
          'Clouds': 'https://img.icons8.com/clouds/100/000000/clouds.png',
          'Mist': 'https://img.icons8.com/clouds/100/000000/fog-day.png',
          'Smoke': 'https://img.icons8.com/clouds/100/000000/fog-day.png',
          'Haze': 'https://img.icons8.com/clouds/100/000000/fog-day.png',
          'Dust': 'https://img.icons8.com/clouds/100/000000/fog-day.png',
          'Fog': 'https://img.icons8.com/clouds/100/000000/fog-day.png',
          'Sand': 'https://img.icons8.com/clouds/100/000000/fog-day.png',
          'Ash': 'https://img.icons8.com/clouds/100/000000/fog-day.png',
          'Squall': 'https://img.icons8.com/clouds/100/000000/fog-day.png',
          'Tornado': 'https://img.icons8.com/clouds/100/000000/tornado.png',
        };

        return weatherIcon[keyword];
      };

      getWeatherColor(keyword) {
        const weatherColor = {
          'Thunderstorm': 'rgb(128, 0, 128)', // 보라색: 천둥번개
          'Drizzle': 'rgb(173, 216, 230)', // 연한 파란색: 이슬비
          'Rain': 'rgb(0, 0, 255)', // 파란색: 비
          'Snow': 'rgb(255, 250, 250)', // 하얀색: 눈
          'Clear': 'rgb(255, 223, 0)', // 노란색: 맑음
          'Clouds': 'rgb(169, 169, 169)', // 회색: 구름
          'Mist': 'rgb(211, 211, 211)', // 연한 회색: 안개
          'Smoke': 'rgb(105, 105, 105)', // 어두운 회색: 연기
          'Haze': 'rgb(192, 192, 192)', // 은색: 연무
          'Dust': 'rgb(210, 180, 140)', // 황갈색: 먼지
          'Fog': 'rgb(169, 169, 169)', // 회색: 안개
          'Sand': 'rgb(244, 164, 96)', // 모래색: 모래
          'Ash': 'rgb(112, 128, 144)', // 슬레이트 회색: 재
          'Squall': 'rgb(0, 191, 255)', // 딥 스카이 블루: 돌풍
          'Tornado': 'rgb(128, 0, 0)', // 어두운 빨간색: 토네이도
        };

        return weatherColor[keyword];
      };

      getWeatherKeywordFromDescription(description) {
        description = description.toLowerCase();
        let keyword = '';

        switch (true) {
          case description.includes('rain'):
            keyword = 'Rain';
            break;
          case description.includes('snow'):
            keyword = 'Snow';
            break;
          case description.includes('clear'):
            keyword = 'Clear';
            break;
          case description.includes('clouds'):
            keyword = 'Clouds';
            break;
          case description.includes('mist'):
            keyword = 'Mist';
            break;
          case description.includes('smoke'):
            keyword = 'Smoke';
            break;
          case description.includes('thunderstorm'):
            keyword = 'Thunderstorm';
            break;
          case description.includes('drizzle'):
            keyword = 'Drizzle';
            break;
          case description.includes('haze'):
            keyword = 'Haze';
            break;
          case description.includes('dust'):
            keyword = 'Dust';
            break;
          case description.includes('fog'):
            keyword = 'Fog';
            break;
          case description.includes('sand'):
            keyword = 'Sand';
            break;
          case description.includes('ash'):
            keyword = 'Ash';
            break;
          case description.includes('squall'):
            keyword = 'Squall';
            break;
          case description.includes('tornado'):
            keyword = 'Tornado';
            break;
          default:
            keyword = description;
            break;
        }

        return keyword;
      }

      getBrightness(rgb) {
        const [r, g, b] = rgb.match(/\d+/g).map(Number);
        return (r * 299 + g * 587 + b * 114) / 1000;
      }

      onAdd() {
        const div = document.createElement('div');
        div.className = 'custom-marker';

        const weatherKeyword = this.getWeatherKeywordFromDescription(data.weather[0].description);
        const backgroundColor = this.getWeatherColor(weatherKeyword);
        const brightness = this.getBrightness(backgroundColor);
        const textColor = brightness > 128 ? 'black' : 'white'; // 밝기에 따라 글자 색상 설정

        const iconImage = this.getWeatherIcon(weatherKeyword);
        console.log(`cityname:${city.name} description:${data.weather[0].description} weatherKeyword: ${weatherKeyword}, backgroundColor: ${backgroundColor}, iconImage: ${iconImage}`);
        div.innerHTML = `
        <div style="background-color: ${backgroundColor}; color: ${textColor}; position: absolute; transform: translate(-50%, -100%); z-index: 1000;">
          <div>
            <span style="font-size: 12px;">${city.name}</span>
          </div>
          <div>
            <img src=${iconImage} style="width: 100px;" alt="rain-icon"/>
            <span style="font-size: 12px;">${data.weather[0].description}</span>
          </div>
          <div>
            ${data.main.temp}°C
          </div>
          <div style="background-color: ${backgroundColor}; z-index: -1; border-radius: inherit;"></div>
        </div>
      `;

        this.div = div;

        const panes = this.getPanes();
        panes.overlayImage.appendChild(div);
      }
    }

    const customMarker = new CustomMarker(position, city, data);
    customMarker.setMap(map);
    bounds.extend(position);
  }

  const clearMarkers = () => {
    markers.forEach(marker => marker.setMap(null));
    setMarkers([]);
    // console.log('clearMarkers');
  };

  const displayPlaces = (map, places) => {
    const newMarkers = places.map(place => {
      const marker = new window.google.maps.Marker({
        position: place.geometry.location,
        map: map,
        title: place.name
      });

      const infoWindow = new window.google.maps.InfoWindow({
        content: `<div><h3>${place.name}</h3><p>${place.vicinity}</p></div>`
      });

      marker.addListener('click', () => {
        infoWindow.open(map, marker);
      });

      return marker;
    });

    setMarkers(prevMarkers => [...prevMarkers, ...newMarkers]);
  };

  // useEffect(() => {
  //   console.log(`marker set length: ${markers.length}`);
  // }, [markers]);

  const fetchPlaces = (map, radius, placeType, keyword) => {
    return new Promise((resolve, reject) => {
      const service = new window.google.maps.places.PlacesService(map);
      const request = {
        location: map.getCenter(),
        radius: radius,
        type: placeType,
        keyword: keyword
      };

      service.nearbySearch(request, (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          console.log(`google place(nearbySearch, ${placeType}, ${keyword}, ${radius}):`, results);
          resolve(results);
        } else {
          console.error(`PlacesServiceStatus not OK: ${status}`);
          reject(new Error(`PlacesServiceStatus not OK: ${status}`));
        }
      });
    });
  };

  const calculateRadius = (bounds, center) => {
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    const radius = window.google.maps.geometry.spherical.computeDistanceBetween(center, ne);
    return radius;
  };

  return <GoogleMap onMapLoad={onMapLoad} />;
};

export default SearchBeach;